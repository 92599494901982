import BurgerMenu from "../modules/BurgerMenu";
import ContactButton from "../modules/Contact";
import Loader from "../modules/Loader";
import Overlays from "../modules/Overlays";
import Parallax from "../modules/Parallax";
import ReferencesGallery from "../modules/ReferencesGallery";
import SectionManager from "../modules/SectionManager";
import Texts from "../modules/Texts";
import VisibilityCheck from "../modules/VisibilityCheck";
import URLUtils from "../utils/URLUtils";
import Utils from "../utils/Utils";
import Data from "./Data";


export default class Application {
    private loader: Loader;


    constructor() {
        this.checkLocalstorage();
        this.loadConfig();
    }

    private checkLocalstorage(){
        let accessibility:boolean = false;
        let lsdata:string = Utils.getKey("accessibility");
        if (lsdata){
            try{
                accessibility = JSON.parse(lsdata);
                if (accessibility){
                    let el: HTMLElement = document.querySelector("body");
                    el.classList.add("accessible");
                    Data.isAccessible = true;
                }
            }catch(e:any){

            }
        }
    }
    private loadConfig() {
        this.loader = new Loader(this.configComplete.bind(this), this.error.bind(this));
        this.loader.add({ id: "config", url: "static/json/config.json", method: "get" });
        this.loader.start();
    }
    private error() {
        console.log("error");
    }
    private configComplete() {
        Data.config = this.loader.get("config");
        Data.language = this.loader.get("config").defaultLanguage;
        Data.year = new Date().getFullYear().toString();

        let el:HTMLElement = document.querySelector("header");
        if (el.children.length > 0){
            Data.mode = "prod";
        }

        this.loader = new Loader(this.filesComplete.bind(this), this.error.bind(this));
        let t: Application = this;

        Data.config.languages.forEach((l:string) => {
            t.loader.add({ id: "l-" + l, url: "static/json/" + l + ".json", method: "get" });
        });

        if (Data.mode == "dev") {
            this.loader.add({ id: "html-header", url: "static/html/header.html", method: "get" });
            this.loader.add({ id: "html-footer", url: "static/html/footer.html", method: "get" });
            for (let s in Data.config.sections) {
                this.loader.add({ id: "html-" + s, url: "static/html/" + s + ".html", method: "get" });
            }
        }

        this.loader.start();
    }
    private filesComplete() {
        let texts: any = {};
        let t: Application = this;
        Data.config.languages.forEach((l:string) => {
            texts[l] = t.loader.get("l-" + l);
        });

        Data.texts = texts;

        if (Data.mode == "prod") {
            setTimeout(this.domReady.bind(this), 500);
            return;
        }

        const parser: DOMParser = new DOMParser();
        let doc: any = parser.parseFromString(Utils.relaceHTMLVars(this.loader.get("html-header")), 'text/html');
        Utils.addAllChildrenFromElementToElement(doc.body, document.querySelector("header"));

        doc = parser.parseFromString(Utils.relaceHTMLVars(this.loader.get("html-footer")), 'text/html');
        Utils.addAllChildrenFromElementToElement(doc.body, document.querySelector("footer"));

        for (let s in Data.config.sections) {
            doc = parser.parseFromString(Utils.relaceHTMLVars(this.loader.get("html-" + s)), 'text/html');
            Utils.addAllChildrenFromElementToElement(doc.body, document.querySelector("main"));
        }

        this.setTexts();
        setTimeout(this.domReady.bind(this), 500);
    }
    private domReady(){
        document.body.classList.add("visible");

        URLUtils.init();
        new VisibilityCheck(["#top-figure", "#section-1", "#section-2", "#section-3", "#section-4", "#section-5", "#section-6", "#content-figure1", "#content-figure2", "#content-figure3", "#content-figure4", "#content-figure5"], "visible");
        new Parallax([{ elementQuery: "#top-figure #image1", speed: 8 }, { elementQuery: "#top-figure #image2", speed: 8 }, { elementQuery: "#top-figure #image3", speed: 8 }]);
        new SectionManager();
        new BurgerMenu();
        new ContactButton();
        new ReferencesGallery();
        new Overlays();
    }
    private setTexts() {

    }
}
