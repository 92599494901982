import Data from "../app/Data";

interface Logo{
    name:string,
    image:string
}

export default class ReferencesGallery{
    private logos: Logo[];
    private layerSpeed:number = 1.5;
    private nb:number = 0;
    private images:any[];
    private lines:number = 4;
    private created:boolean = false;

    constructor(){
        Data.set("Gallery", this);

        this.initImages();
    }
    public initImages(){
        this.images = [];
        this.logos = Data.config.sections["section-5"].logos;
        this.nb = Math.floor(this.logos.length / this.lines);
        let j: number = 0;
        let k: number = 0;

        if (Data.mode != "dev" || this.created){
            let imagesLogo: NodeListOf<HTMLElement> = document.querySelectorAll("#refs-gallery .image");

            for (let i = 0; i < this.logos.length; i++) {
                if (!this.images[k]) this.images[k] = [];
                this.images[k].push(imagesLogo[i]);

                imagesLogo[i].style.left = (j * 170) + "px";
                imagesLogo[i].style.top = (k * 90) + "px";

                j ++;
                if (j >= this.nb) {
                    j = 0;
                    k++;
                }
            }

            this.animateLayers();
            return;
        }

        let layer: HTMLElement = document.querySelector("#refs-gallery");
        j = 0;
        k = 0;
        for(let i=0; i<this.logos.length; i++){
            if (!this.images[k]) this.images[k] = [];

            let image: HTMLElement = document.createElement("div");
            this.images[k].push(image);

            image.classList.add("image");
            image.style.backgroundImage = "url('static/images/logos/" + this.logos[i].image + "')";
            image.title = this.logos[i].name;
            layer.append(image);
            image.style.left = (j * 170) + "px";
            image.style.top = (k * 90) + "px";

            j ++;
            if (j >= this.nb){
                j = 0;
                k ++;
            }
        }

        this.created = true;
        if (!Data.isAccessible) this.animateLayers();
    }
    public resetImages(){
        // let a: number = 0;
        // for (let i = 0; i < this.logos.length; i++) {
        //     for (let k = 0; k < this.images[i].length; k++){

        //     }
        // }
    }
    private animateLayers(){
        if(Data.isAccessible){
            return;
        }


        let containerWidth: number;

        for (let i = 0; i < this.lines; i++) {
            for (let j = 0; j < this.images[i].length; j++){
                let image: HTMLElement = this.images[i][j];

                let pos:number = Math.round(Number(image.style.left.replace("px", "")));

                if(i != 1 && i != 3){
                    if (pos <= -170){
                        pos = (((this.images[i].length - 1)) * 170);
                    }
                    pos -= this.layerSpeed;
                }else{
                    if(i == 1) containerWidth = (this.images[1].length - 1) * 170;
                    else if(i == 3) containerWidth = (this.images[3].length - 1) * 170;

                    if (pos >= containerWidth) {
                        pos = -170;
                    }
                    pos += this.layerSpeed;
                }

                image.style.left = pos + "px";
            }
        }

        requestAnimationFrame(this.animateLayers.bind(this));
    }
}