import Utils from "../utils/Utils";

export default class VisibilityCheck {
    private elementsQuery: string[];
    private visibleClass: string;
    private elementsVisible: number = 0;



    constructor(elementsQuery: string[], visibleClass: string) {
        this.elementsQuery = elementsQuery;
        this.visibleClass = visibleClass;

        this.checkVisibility();
        document.addEventListener("scroll", this.checkVisibility.bind(this));
    }
    private checkVisibility(e?: any) {
        let a: number = this.elementsQuery.length;
        for (let i = 0; i < a; i++) {
            let element: HTMLElement = document.querySelector(this.elementsQuery[i]);
            let elementVisibility: boolean = Utils.isElementInViewPort(element);

            // let rect: DOMRect = element.getBoundingClientRect();
            // if (this.elementsQuery[i] == "#section-1") console.log(rect, window.scrollY);

            if (elementVisibility && !element.classList.contains(this.visibleClass)) {
                //console.log(this.elementsQuery[i] + " visible");
                element.classList.add(this.visibleClass);
                this.elementsVisible++;
            }
        }

        if (this.elementsVisible == a) {
            document.removeEventListener("scroll", this.checkVisibility.bind(this));
        }
    }
}