import Texts from "../modules/Texts";

export default class Utils {
    static generateUID(l: number = 15): string {
        //if(length) l = length;
        let uid: string = "";
        let chars: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < l; i++) {
            uid += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return uid;
    }
    static copy(data: any) {
        if (data) return JSON.parse(JSON.stringify(data));
    }
    static replace(text: string, expression: string, replace_expression: string) {
        return text.replace(new RegExp(expression, "g"), replace_expression);
    }
    static getTime() {
        return new Date().toISOString();
    }
    static setKey(key:string, data:any){
        if(localStorage) localStorage.setItem(key, data);
    }
    static getKey(key: string) {
        if (localStorage) return localStorage.getItem(key);
    }
    static deleteKey(key:string){
        if (localStorage) localStorage.removeItem(key);
    }
    static isElementInViewPort(element: HTMLElement, entireElementVisible: boolean = false) {
        if (!element) return false;

        let rect: DOMRect = element.getBoundingClientRect();
        let viewportY: number = 0;
        let viewPortBottom: number = window.innerHeight || document.documentElement.clientHeight;
        let viewPortRight: number = window.innerWidth || document.documentElement.clientWidth;

        let isTopInViewPort: boolean = rect.top - viewportY >= 0;
        let isLeftInViewPort: boolean = rect.left >= 0;
        let isBottomInViewPort: boolean = rect.bottom - viewportY <= viewPortBottom;
        let isRightInViewPort: boolean = rect.right <= viewPortRight;


        if (!entireElementVisible) {
            isTopInViewPort = rect.top - viewportY <= viewPortBottom;
            isLeftInViewPort = rect.left >= 0;
            isBottomInViewPort = rect.bottom - viewportY >= 0;
            isRightInViewPort = rect.right <= viewPortRight;
        }

        return (isTopInViewPort && isLeftInViewPort && isBottomInViewPort && isRightInViewPort);
    }
    static getFileExtension(filename: string) {
        return filename.split('.').pop();
    }
    static getHTMLVars(str: string):string[]{
        let tab: string[] = str.match(new RegExp("{(.*?)}", "g"));
        return tab;
    }
    static relaceHTMLVars(str:string):string{
        let tab: string[] = this.getHTMLVars(str);

        if (!tab || tab.length == 0) return str;

        for (let i = 0; i < tab.length; i++){
            let key:string = this.replace(tab[i], "{", "");
            key = this.replace(key, "}", "");

            let text: string = Texts.get(key);

            if (text && text != "") str = this.replace(str, tab[i], Texts.get(key));
        }

        return str;
    }
    static addAllChildrenFromElementToElement(from:HTMLElement, to:HTMLElement){
        while (from.children.length > 0){
            to.append(from.children[0]);
        }
    }
}