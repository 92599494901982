import Utils from "../utils/Utils";

export default class Loader {
    public files: any[] = [];
    public data: any = {};
    public response: any = {};
    public index: number = 0;
    public onComplete: any;
    public onError: any;



    constructor(onComplete: any = null, onError: any = null) {
        this.onComplete = onComplete;
        this.onError = onError;
    }
    public add(file: any) {
        file.extension = file.url.split('.').pop();

        this.files.push(file);
    }
    public get(id: string) {
        return this.data[id];
    }
    public getFile(id: string) {
        for (let i = 0; i < this.files.length; i++) {
            if (this.files[i].id == id) {
                return this.files[i];
            }
        }
    }
    public getResponse(id: string) {
        return this.response[id];
    }
    public start() {
        this.loadNextFile();
    }
    private async loadNextFile() {
        if (this.index >= this.files.length || this.files.length == 0) {
            //console.log(this.index + "/" + this.files.length, "filesComplete");
            this.filesComplete();
        } else {
            const file: any = this.files[this.index];
            file.method = file.method.toLowerCase();

            let settings: any = {
                url: file.url,
                method: file.method,
                crossDomain: true,
                cache: file.cache || "no-cache",
                headers: file.headers || {}
            }

            if (file.method == "post" || file.method == "post") {
                settings.body = file.data;
                settings.contentType = file.contentType;
                settings.processData = false;
            }

            if (!file.cache && settings.method == "get") {
                // settings.url += "?" + Utils.generateUID();
                settings.headers["Cache-Control"] = "no-cache";
                settings.headers["Pragma"] = "no-cache";
                settings.headers["Expires"] = "0";
            }


            file.startDate = new Date();

            //await response = fetch(settings.url).then(this.fileComplete.bind(this)).catch(this.fileError.bind(this));
            const response: Response = await fetch(settings.url, settings);
            let data: any;
            let extension: string = Utils.getFileExtension(file.url);
            if (extension == "json") data = await response.json();
            else data = await response.text();

            this.fileComplete(response, data);
        }
    }
    private addHeaders(headers: any) {
        console.log(headers);
    }
    private fileComplete(response: Response, data: any) {
        let file: any = this.files[this.index];

        file.completeDate = new Date();
        file.loadDuration = (file.completeDate.getTime() - file.startDate.getTime()) / 1000;

        this.response[file.id] = response;

        //try{
        this.index++;
        let t: Loader = this;

        this.data[file.id] = data;
        this.loadNextFile();
        /*}catch(e){
            this.fileError(null);
        }*/
    }
    private fileError(response: any) {
        let file: any = this.files[this.index];

        file.completeDate = new Date();
        file.loadDuration = (file.completeDate.getTime() - file.startDate.getTime()) / 1000;

        this.response[file.id] = response;

        if (this.onError) {
            this.onError();
            return;
        }

        this.index++;
        this.loadNextFile();
    }
    private filesComplete() {
        if (this.onComplete) this.onComplete();
    }
    public destroy() {
        this.files = null;
        this.data = null;
        this.response = null;
        this.onComplete = null;
        this.onError = null;
    }
}