export interface ParallaxParams {
    elementQuery: string,
    speed: number
}

export default class Parallax {
    private elementsQuery: ParallaxParams[];
    private elements: HTMLElement[];

    constructor(elementsQuery: ParallaxParams[]) {
        this.elementsQuery = elementsQuery;
        this.elements = [];

        for (let i = 0; i < elementsQuery.length; i++) {
            this.elements.push(document.querySelector(elementsQuery[i].elementQuery));
        }

        this.update();
        document.addEventListener("scroll", this.update.bind(this));
    }
    private update(e?: any) {
        // this.element.setAttribute(
        //     "style",
        //     "background-repeat: no-repeat; background-attachment: fixed; background-size: cover;background-position: center center;"
        // );

        let h: number = window.innerHeight;
        //console.log(pageY);


        for (let i = 0; i < this.elements.length; i++) {
            let elBounds: DOMRect = this.elements[i].getBoundingClientRect();

            let x: number = elBounds.top / this.elementsQuery[i].speed;
            let y: number = Math.round(x * 100) / 100;

            // y = - (h - elBounds.top) / this.elementsQuery[i].speed;

            this.elements[i].style.backgroundPosition = "center "+ y + "px";
        }
    };
}