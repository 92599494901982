import './scss/styles.scss';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import Application from './ts/app/Application';
import Consent from './ts/app/Consent';


window.addEventListener("load", ready);

function ready(e:Event){
    window.removeEventListener("load", ready);
    window.scrollTo(0, 0);
    new Consent();
    new Application();
}