import Consent from "../app/Consent";

export default class Overlays{
    constructor(){
        this.init();
    }
    private init(){
        let el: HTMLImageElement = document.querySelector("#overlay img");
        el.onclick = this.closeOverlay.bind(this);

        el = document.querySelector("#mentions-button");
        el.onclick = this.openOverlay.bind(this);

        el = document.querySelector("#overlay .bg");
        el.onclick = this.closeOverlay.bind(this);

        el = document.querySelector("#cookies-button");
        el.onclick = this.openCookies.bind(this);
    }
    private openOverlay(){
        let el: HTMLImageElement = document.querySelector("#overlay");
        el.classList.remove("visible");
        el.classList.add("visible");

        let body: HTMLElement = document.querySelector("body");
        body.classList.remove("has-overlay");
        body.classList.add("has-overlay");
    }
    private closeOverlay(e?:Event){
        let el: HTMLImageElement = document.querySelector("#overlay");
        el.classList.remove("visible");

        let body:HTMLElement = document.querySelector("body");
        body.classList.remove("has-overlay");
    }
    private openCookies(){
        Consent.show();
    }
}