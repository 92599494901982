import * as CookieConsent from "vanilla-cookieconsent";

export default class Consent{
    constructor(){
        let data: any = {
            autoClearCookies:true,
            onFirstConsent: this.manageCookies.bind(this),
            onConsent: this.manageCookies.bind(this),
            onChange: this.manageCookies.bind(this),
            categories: {
                necessary: {
                    enabled: true,  // this category is enabled by default
                    readOnly: true  // this category cannot be disabled
                },
                analytics: {
                    enabled: false,
                    autoClear: {
                        cookies: [
                            {
                                name: /^_ga/,   // regex: match all cookies starting with '_ga'
                            },
                            {
                                name: /^_gid/,   // string: exact cookie name
                            }
                        ]
                    }
                }
            },
            guiOptions: {
                consentModal: {
                    layout: 'box',
                    position: 'bottom left',
                    flipButtons: false,
                    equalWeightButtons: true
                },
                preferencesModal: {
                    layout: 'box',
                    // position: 'left right',
                    flipButtons: false,
                    equalWeightButtons: true
                }
            },
            language: {
                default: 'fr',
                translations: {
                    fr: {
                        consentModal: {
                            title: 'Nous utilisons des cookies',
                            description: 'Nous utilisons les cookies pour assurer des fonctionnalités basiques du site web pour améliorer votreexpérience.',
                            acceptAllBtn: 'Accepter tout',
                            acceptNecessaryBtn: 'Refuser tout',
                            showPreferencesBtn: 'Je veux vérifier en détail'
                        },
                        preferencesModal: {
                            title: 'Gérer les cookies en détail',
                            acceptAllBtn: 'FInalement j&apos;accepte tout',
                            acceptNecessaryBtn: 'Pas moyen, j&apos;en veux pas&nbsp;!',
                            savePreferencesBtn: 'Seulement ceux que j&apos;choisis.',
                            closeIconLabel: 'Ferme la fenêtre (ça caille)',
                            sections: [
                                {
                                    title: 'Les cookies web sont potentielleent indigestes&nbsp;?',
                                    description: 'Évaluez sereinement leur nutri-score et faites un choix éclairé avant de les consommer.'
                                },
                                {
                                    title: 'Cookies strictement nécessaires',
                                    description: 'Ces cookies sont essentiels au bon fonctionnement du site et ne peuvent être désactivés. Ce sont des outils techniques, ils n&apos;établissent aucun lien avec vous.',

                                    //this field will generate a toggle linked to the 'necessary' category
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Mesure de la performance et statistiques d&apos;utilisation',
                                    description: 'Ces cookies collectent des informations sur la manière dont vous utilisez notre site Internet. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier.',
                                    linkedCategory: 'analytics'
                                },
                                {
                                    title: 'More information',
                                    description: 'For any queries in relation to my policy on cookies and your choices, please <a href="/#/contact">contact us</a>'
                                }
                            ]
                        }
                    }
                }
            }
        }
        CookieConsent.run(data);


        let userData: any = CookieConsent.getUserPreferences();
        if (userData && userData.acceptedCategories.indexOf("analytics") != -1) {
            this.iniGStats();
        }else{
            //console.log("erase cookies GA");
            CookieConsent.eraseCookies(/^_ga/);
            CookieConsent.eraseCookies(/^_gid/);
        }
    }
    static show(){
        CookieConsent.showPreferences();
    }
    private manageCookies(data, changedCategories, changedServices){
        //console.log("manageCookies", cookie);
        //console.log(cookie);

        if (data.cookie && data.cookie.categories && data.cookie.categories.indexOf("analytics") != -1) {
            this.iniGStats();
        } else {
            //console.log("erase cookies GA");
            CookieConsent.eraseCookies(/^_ga/);
            CookieConsent.eraseCookies(/^_gid/);
        }
    }
    private iniGStats(){
        //@ts-ignore
        window.dataLayer = window.dataLayer || [];
        //@ts-ignore
        function gtag() { dataLayer.push(arguments); }
        //@ts-ignore
        gtag('js', new Date());
        //@ts-ignore
        gtag('config', 'G-4KHS3RCHVM');
    }
}