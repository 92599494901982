import Data from "../app/Data";
import BurgerMenu from "./BurgerMenu";

export default class ContactButton{
    constructor(){
        let el: HTMLElement = document.querySelector("#contact-button");
        el.addEventListener("click", this.openContact.bind(this));
    }
    private openContact(){
        let burgerMenu: BurgerMenu = Data.get("BurgerMenu") as BurgerMenu;
        burgerMenu.openSection("section-6");
    }
}