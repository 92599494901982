import Data from "../app/Data";
import Utils from "../utils/Utils";
import ReferencesGallery from "./ReferencesGallery";

export default class BurgerMenu{
    constructor(){
        let el: HTMLElement = document.querySelector("#burger-button #open-menu-button");
        el.addEventListener("click", this.toggleMenu.bind(this));

        el = document.querySelector("#burger-button #close-menu-button");
        el.addEventListener("click", this.closeMenu.bind(this));

        el = document.querySelector("#menu");
        el.addEventListener("click", this.stopMenuFromClosing.bind(this));

        window.addEventListener("click", this.clickOutsideMenu.bind(this));

        for (let s in Data.config.sections) {
            el = document.querySelector("#menu-button-" + s);
            el.onclick = this.openSection.bind(this, s);
        }


        el = document.querySelector("#accessibility-button");
        el.addEventListener("click", this.toggleAccessibility.bind(this));

        el = document.querySelector("#accessibility-link");
        el.addEventListener("click", this.toggleAccessibility.bind(this));

        this.updateMenu();

        Data.set("BurgerMenu", this);
    }
    private toggleMenu(e?: Event) {
        e.stopPropagation();

        Data.menu = !Data.menu;

        let el: HTMLElement = document.querySelector("#menu");
        if (Data.menu) {
            el.classList.add("visible");

            el = document.querySelector("#open-menu-button");
            el.style.display = "none";

            el = document.querySelector("#close-menu-button");
            el.style.display = "block";
        } else {
            this.closeMenu();
        }
    }
    private closeMenu() {
        let el: HTMLElement = document.querySelector("#menu");
        el.classList.remove("visible");
        Data.menu = false;

        el = document.querySelector("#open-menu-button");
        el.style.display = "block";

        el = document.querySelector("#close-menu-button");
        el.style.display = "none";
    }
    private clickOutsideMenu(e: Event) {
        //console.log("clickOutsideMenu");
        this.closeMenu();
    }
    private stopMenuFromClosing(e: Event) {
        //console.log("stopMenuFromClosing");
        e.stopPropagation();
    }
    public updateMenu(){
        // let el: HTMLElement = document.querySelector("#menu-button-home");
        // el.classList.remove("selected");
        // if (Data.section == "home") el.classList.add("selected");

        for (let s in Data.config.sections) {
            let el: HTMLElement = document.querySelector("#menu-button-" + s);
            el.classList.remove("selected");
            if(s == Data.section) el.classList.add("selected");
        }
    }
    public openSection(s: string, e?: Event) {
        let scrollPos: number = 0;

        if (s != "section-home") {
            let el: HTMLElement = document.querySelector(Data.config.sections[s].anchor);
            let rect: DOMRect = el.getBoundingClientRect();
            scrollPos = Math.round(rect.top);
            //if (scrollPos < 0) scrollPos *= -1;
            scrollPos += window.scrollY;
        }

        window.scrollTo({ left: 0, top: scrollPos, behavior: "smooth" });

        setTimeout(this.closeMenu.bind(this), 1000);

        //let sectionManager: SectionManager = Data.get("SectionManager") as SectionManager;
        //sectionManager.setSection(s);
    }
    private toggleAccessibility(e:Event){
        let gallery: ReferencesGallery = Data.get("Gallery");


        let el:HTMLElement = document.querySelector("body");
        if (el.classList.contains("accessible")){
            el.classList.remove("accessible");
            Utils.deleteKey("accessibility");
            Data.isAccessible = false;

            gallery.initImages();
        }else{
            el.classList.add("accessible");
            Utils.setKey("accessibility", true);
            Data.isAccessible = true;
            gallery.resetImages();
        }
    }
}