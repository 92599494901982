import Data from "../app/Data";
import URLUtils from "../utils/URLUtils";
import BurgerMenu from "./BurgerMenu";

export default class SectionManager{
    constructor(){
        this.checkPosition();
        window.addEventListener("scroll", this.checkPosition.bind(this));
        window.addEventListener("scrollend", this.checkPosition.bind(this));

        Data.set("SectionManager", this);
    }
    private checkPosition(e?:Event){
        //console.log(window.scrollY);
        let height: number = window.innerHeight || document.documentElement.clientHeight;
        let currentSection:string = "home";

        for(let s in Data.config.sections){
            let element: HTMLElement = document.querySelector(Data.config.sections[s].anchor);
            let rect: DOMRect = element.getBoundingClientRect();

            //console.log(s, rect);
            if (rect.top < height / 6) currentSection = s;
        }

        //console.log("currentSection ", currentSection);

        this.setSection(currentSection);

        URLUtils.setURLFromData();
    }
    public setSection(s:string){
        Data.section = s;

        let header:HTMLElement = document.querySelector("header");
        header.classList.forEach(c =>{
            header.classList.remove(c);
        });

        header.classList.add(Data.config.sections[s].themeClass);

        let main: HTMLElement = document.querySelector("main");
        main.classList.forEach(c => {
            main.classList.remove(c);
        });

        main.classList.add(Data.config.sections[s].themeClass);


        let burgerMenu: BurgerMenu = Data.get("BurgerMenu") as BurgerMenu;
        if (burgerMenu) burgerMenu.updateMenu();
    }
}