import Data from "../app/Data";
import SectionManager from "../modules/SectionManager";
import Texts from "../modules/Texts";

export default class URLUtils {
    public static previousHash: string;
    public static hash: string;
    public static params: string;
    private static hashChangeDelay: number = 200;

    public static paused: boolean = false;
    public static status: boolean = false;


    public static init() {
        let hash: string = window.location.hash;

        if (!hash) return;
        hash = hash.replace("#/", "");

        this.setDataFromURL();
        this.startDetectURLChange();
    }
    public static startDetectURLChange() {
        this.status = true;

        window.addEventListener("hashchange", this.setDataFromURL.bind(this));
    }
    public static stopDetectURLChange() {
        this.status = false;

        window.removeEventListener("hashchange", this.setDataFromURL.bind(this));
    }
    private static formatStringToUrl(value: string) {
        if (!value) return value;

        value = value.toLowerCase();
        return value.replace(/[^a-zA-Z0-9]/g, '');
    }
    public static setHash(hash: string, replace: boolean = false) {
        //window.history.pushState(null, null, "#/" + hash);
        //
        if (!replace) window.location.hash = "#/" + hash;
        //if (!replace) window.location.hash = "#/" + hash;
        //if (!replace) window.history.pushState(null, null, "#/" + hash);
        else window.history.replaceState(null, null, "#/" + hash);

        this.previousHash = hash;
    }
    private static resume() {
        this.paused = false;
    }







    public static setDataFromURL() {
        if (this.paused == true) return;

        this.paused = true;
        setTimeout(this.resume.bind(this), this.hashChangeDelay);

        this.hash = window.location.hash;

        if (!this.hash || this.hash == "") {
            //open home
            return;
        }

        this.hash = this.hash.replace("#/", "");
        let tab: any = this.hash.split("?");
        this.hash = tab[0];
        this.params = tab[1];


        let currentSection:string = "home";
        tab = this.hash.split("/");
        if (tab[0] && tab[0] != undefined){
            for (let s in Data.config.sections) {
                if (tab[0] == Texts.get("url-" + s)){
                    currentSection = s;
                }
            }
        }

        let sectionManager: SectionManager = Data.get("SectionManager") as SectionManager;
        if (sectionManager) sectionManager.setSection(currentSection);
        else Data.section = currentSection;

        let scrollPos:number = 0;
        if(Data.section != "home"){
            let element: HTMLElement = document.querySelector(Data.config.sections[currentSection].anchor);
            let rect: DOMRect = element.getBoundingClientRect();
            scrollPos = Math.round(rect.top);
            if (scrollPos < 0) scrollPos *= -1;
        }

        //console.log(Data.section, scrollPos);

        window.scrollTo(0, scrollPos);

        this.setURLFromData(true);
    }
    public static setURLFromData(force: boolean = false, replaceURL: boolean = false) {
        if (force == false) {
            if (this.paused == true) return;
        }

        this.paused = true;
        setTimeout(this.resume.bind(this), this.hashChangeDelay);

        let hash: string = Texts.get("url-" + Data.section);
        let params: string = "";

        if (this.previousHash != hash + params) this.setHash(hash + params, replaceURL);
    }

}