export default class Data {
    public static mode: string = "dev";
    public static pool: any = {};
    public static config: any;
    public static texts: any;
    public static section: string;
    public static language: string;
    public static year: string;
    public static menu: boolean = false;
    public static isAccessible: boolean = false;

    public static get(id):any{
        return this.pool[id];
    }
    public static set(id, o:any) {
        this.pool[id] = o;
    }
}